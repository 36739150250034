import {
	FlatList,
	Divider,
	Box,
	Text,
	Heading,
	Pressable,
	HStack,
	Image,
	Icon,
	EditIcon,
	Tooltip,
	TooltipContent,
	TooltipText,
	VStack,
} from '@gluestack-ui/themed'
import React, { useEffect } from 'react'
import { Dimensions } from 'react-native'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { showToast } from '../components/ErrorToast'
import { OpenHidiPublicView } from '../HeplerFunctions/OpenHidiView'
import useUserStore from '../Userstore/UserStore'
import { useState } from 'react'
import SpinnerComponent from './SpinnerComponent'
import { checkAiText } from '../customhook/CheckAiText'
import AiBadge from './AiBadge'
import { API } from 'aws-amplify'
import { updateNewhidi } from '../graphql/custom-mutations'
import ButtonComponent from './ButtonComponent'

export default function CapabilityHidiOnProfile({
	UnlockedCapability,
	PreferenceId,
	capabilitiesClaimedLength,
	evidences,
	capabilities,
	capabilitiesClaimed,
	navigation,
	view,
	inModal,
	unmatchedCapabilityClaimed,
}) {
	
	const profile = useUserStore((state) => state.profile)
	const [expand, setExpand] = useState(false)
	const typeOfUser = useUserStore((state) => state.group)
	const [existingId, setExistingId] = useState({})
	const [gptGenerated, setGptGenerated] = useState({})
	const [gptLoading, setGptLoading] = useState('')
	const [loadingCheckAI, setLoadingCheckAI] = useState({})
	const [fakeHidiProbability, setFakeHidiProbability] = useState({})
	const [realHidiProbability, setRealHidiProbability] = useState({})
	const [loadingHumanAnalysis, setLoadingHumanAnalysis] = useState({});
	const [updateFeedback, setUpdateFeedback] = useState('');
	const [hidis, setHidis] = useState({});

	const generateCapabilityData = () => {
		return (
			capabilities?.map((data) => {
				const found = capabilitiesClaimed?.some(
					(cap) =>
						cap.capabilitiesCapabilityConfigId ===
						data.CapabilityConfig?.id
				)

				return {
					name: data.CapabilityConfig?.name,
					color: found ? '$primary400' : '$fontGray200',
					key: data.CapabilityConfig?.id,
				}
			}) || []
		)
	}

	const capabilitiesWithoutEvidence = generateCapabilityData()

	capabilitiesWithoutEvidence.sort((a, b) => {
		const colorOrder = {
			$primary400: 0,
			$fontGray200: 1,
		}

		const orderA = colorOrder[a.color]
		const orderB = colorOrder[b.color]

		return orderA - orderB
	})
	
	const DataDisplay = ({ text, title }) => {
		return (
			<Box marginVertical={5}>
				<Text color="#A0AEC0" fontSize={12} fontFamily="$fontHeading">
					{title}
				</Text>
				<Text fontFamily="$fontContent" fontSize={13}>
					{text}
				</Text>
			</Box>
		)
	}
	const updateNewHidiHumanAnalysis = async (hidiId, MeatFoundbyHuman) => {
		setLoadingHumanAnalysis(prev => ({ ...prev, [hidiId]: true }));
		try {
			const response = await API.graphql({
				query: updateNewhidi,
				variables: { 
					input: {
						id: hidiId,
						MeatFoundbyHuman: MeatFoundbyHuman
					}
				},
			});
			
			setHidis(prev => ({
				...prev,
				[hidiId]: {
					...prev[hidiId],
					MeatFoundbyHuman: MeatFoundbyHuman
				}
			}));

			showToast({
				message: "Successfully set to " + (MeatFoundbyHuman ? 'True' : 'False'),
				type: 'success'
			});
			return response.data.updateNewhidi;
		} catch (error) {
			console.error('Error updating HIDI:', error);
			showToast({
				message: 'Error updating status',
				type: 'error'
			});
			throw error;
		} finally {
			setLoadingHumanAnalysis(prev => ({ ...prev, [hidiId]: false }));
		}
	};

	async function updateHidis(
		newHidiId,
		aiGenerated,
		probabilityFake,
		probabilityReal
	) {
		setGptLoading(newHidiId)
		const CreateUserInput = {
			id: newHidiId,
			AiGenerated: aiGenerated,
			ProbabilityFake: probabilityFake,
			ProbabilityReal: probabilityReal,
		}
		await API.graphql({
			query: updateNewhidi,
			variables: { input: CreateUserInput },
		}).then((response) => {
			
			setGptGenerated((prev) => ({
				...prev,
				[newHidiId]: aiGenerated,
			}))
			setFakeHidiProbability((prev) => ({
				...prev,
				[newHidiId]: probabilityFake,
			}))
			setRealHidiProbability((prev) => ({
				...prev,
				[newHidiId]: probabilityReal,
			}))
			setGptLoading('')
		})
	}

	// const checkAiTextAndUpdate = async (id, combinedText) => {
	// 	if (existingId[id] !== undefined) {
	// 		console.log('already checked')
	// 		return
	// 	}

	// 	if (combinedText) {
	// 		setExistingId((prev) => ({
	// 			...prev,
	// 			[id]: true,
	// 		}))
	// 		setLoadingCheckAI((prev) => ({
	// 			...prev,
	// 			[id]: true,
	// 		}))
	// 		// await checkAiText(combinedText)
	// 		// 	.then(async (response) => {
	// 		// 		console.log('checked ai text', response)
	// 		// 		setGptGenerated((prev) => ({
	// 		// 			...prev,
	// 		// 			[id]: response.isGPTGenerated,
	// 		// 		}))
	// 		// 		setFakeHidiProbability((prev) => ({
	// 		// 			...prev,
	// 		// 			[id]: response.fakeProbability,
	// 		// 		}))
	// 		// 		setRealHidiProbability((prev) => ({
	// 		// 			...prev,
	// 		// 			[id]: response.realProbability,
	// 		// 		}))
	// 		// 		await updateHidis(
	// 		// 			id,
	// 		// 			response.isGPTGenerated,
	// 		// 			response.fakeProbability,
	// 		// 			response.realProbability
	// 		// 		)
	// 		// 		setLoadingCheckAI((prev) => ({
	// 		// 			...prev,
	// 		// 			[id]: false,
	// 		// 		}))
	// 		// 	})
	// 		// 	.catch((err) => {
	// 		// 		console.error(err)
	// 		// 		setLoadingCheckAI((prev) => ({
	// 		// 			...prev,
	// 		// 			[id]: false,
	// 		// 		}))
	// 		// 	})
	// 	}
	// }

	const renderItem = ({ item }) => {
		
		const experienceText = item?.experience || ''
		const isAiGenerated = item?.AiGenerated
		const probabilityFake = item?.ProbabilityFake
		const probabilityReal = item?.ProbabilityReal
		const combinedText = `${experienceText}`

		const skillsFound =
			item?.ConnectedCapability?.items[0]?.Capability.JoinedSkills
				.items || null

		// if (isAiGenerated === null) {
		// checkAiTextAndUpdate(item?.id, combinedText)
		// } else if (gptGenerated[item?.id] === undefined) {
		// if (true) {
		// 	console.log('setting gpt generated', item?.id, isAiGenerated)

		// setGptGenerated((prev) => ({
		// 	...prev,
		// 	[item?.id]: isAiGenerated,
		// }))
		// setFakeHidiProbability((prev) => ({
		// 	...prev,
		// 	[item?.id]: item?.ProbabilityFake,
		// }))
		// setRealHidiProbability((prev) => ({
		// 	...prev,
		// 	[item?.id]: item?.ProbabilityReal,
		// }))
		// }

		const splitString = item?.whereitwasdone?.split(/\(([^)]+)\)/)

		const textOutside = splitString ? splitString[0]?.trim() : null
		const textInside = splitString ? splitString[1]?.trim() : null

		const currentMeatFoundbyHuman = hidis[item?.id]?.MeatFoundbyHuman ?? item?.MeatFoundbyHuman;

		return (
			<Pressable
				onPress={() => {
					PreferenceId
						? OpenHidiPublicView(item?.id, PreferenceId)
						: !inModal
						? navigation.push('NewHIDI', {
								hidiId: item?.id,
						  })
						: null
				}}
				marginVertical={windowWidth > 480 ? 10 : 5}
			>
				{loadingCheckAI[item?.id]
					? (typeOfUser === 'QrusibleSuperAdmin' ||
							typeOfUser === 'companys') && (
							<HStack gap={10}>
								<SpinnerComponent size={'small'} />
								<Text
									fontFamily="$fontHeading"
									color="$secondary500"
									fontWeight="600"
								>
									Loading AI Validation...
								</Text>
							</HStack>
					  )
					: (typeOfUser === 'QrusibleSuperAdmin' ||
							typeOfUser === 'companys') && (
							<VStack>
								<HStack alignItems="center" gap={10}>
									{/* <AiBadge
										isGptGenerated={gptGenerated[item?.id]}
									/> */}
									<AiBadge isGptGenerated={isAiGenerated} />
									{typeOfUser === 'QrusibleSuperAdmin' && (
										<Box>
											<ButtonComponent
												text={'Override'}
												height={25}
												width={90}
												onPress={async () => {
													await updateHidis(
														item?.id,
														!gptGenerated[item?.id],
														fakeHidiProbability[
															item?.id
														],
														realHidiProbability[
															item?.id
														]
													)
												}}
												loading={
													gptLoading === item?.id
												}
												customFontSize={
													windowWidth > 480 ? 12 : 10
												}
											/>
										</Box>
									)}
								</HStack>
								{typeOfUser === 'QrusibleSuperAdmin' && (
									<Box>
										<Text
											fontFamily="$fontHeading"
											fontWeight={'600'}
											fontSize={
												windowWidth > 480 ? 12 : 10
											}
										>
											Probability Fake:{' '}
											<Text
												fontFamily="$fontHeading"
												fontWeight={'600'}
												color={'$primary500'}
											>
												{/* {fakeHidiProbability[item?.id]} */}
												{probabilityFake}
											</Text>
										</Text>
										<Text
											fontFamily="$fontHeading"
											fontWeight={'600'}
											fontSize={
												windowWidth > 480 ? 12 : 10
											}
										>
											Probability Real:{' '}
											<Text
												fontFamily="$fontHeading"
												fontWeight={'600'}
												color={'$primary500'}
											>
												{probabilityReal}
												{/* {realHidiProbability[item?.id]} */}
											</Text>
										</Text>
									</Box>
								)}
								{typeOfUser === 'QrusibleSuperAdmin' && (
									<Box>
										
										<Text
											fontFamily="$fontHeading"
											fontWeight={'600'}
											fontSize={
												windowWidth > 480 ? 12 : 10
											}
										>
											Meat Found by AI:{' '}
											<Text
												fontFamily="$fontHeading"
												fontWeight={'600'}
												color={'$primary500'}
											>
												{item?.MeatFoundbyAI === true ? 'Yes' : 
												 item?.MeatFoundbyAI === false ? 'No' : 
												 'Not Analyzed'}
											</Text>
										</Text>
									</Box>
								)}
								{typeOfUser === 'QrusibleSuperAdmin' && (
									<Box>
										
										<Text
											fontFamily="$fontHeading"
											fontWeight={'600'}
											fontSize={
												windowWidth > 480 ? 12 : 10
											}
										>
											Meat Found by Human:{' '}
											<Text
												fontFamily="$fontHeading"
												fontWeight={'600'}
												color={'$primary500'}
											>
												{currentMeatFoundbyHuman === true ? 'Yes' : 
												 currentMeatFoundbyHuman === false ? 'No' : 
												 'Not Analyzed'}
											</Text>
										</Text>
										{item?.MeatFoundbyHuman === undefined || item?.MeatFoundbyHuman === null && (
											<VStack gap={10} mt={2}>
												{updateFeedback && (
													<Text 
														color="$primary500" 
														fontFamily="$fontHeading" 
														fontSize={windowWidth > 480 ? 12 : 10}
													>
														{updateFeedback}
													</Text>
												)}
												<HStack gap={10}>
													<ButtonComponent
														text={'Set True'}
														height={30}
														width={150}
														onPress={async () => {
															await updateNewHidiHumanAnalysis(item?.id, true);
														}}
														loading={loadingHumanAnalysis[item?.id]}
														customFontSize={windowWidth > 480 ? 12 : 10}
													/>
													<ButtonComponent
														text={'Set False'}
														height={30}
														width={150}
														onPress={async () => {
																await updateNewHidiHumanAnalysis(item?.id, false);
														}}
														loading={loadingHumanAnalysis[item?.id]}
														customFontSize={windowWidth > 480 ? 12 : 10}
													/>
												</HStack>
											</VStack>
										)}
									</Box>
								)}
							</VStack>
					  )}
				<HStack marginVertical={windowWidth > 480 ? 5 : 3}>
					<Box flex={1}>
						<Text
							fontWeight="$bold"
							fontFamily="$fontHeading"
							color={'$primary400'}
							fontSize={'$fontSize16'}
						>
							{
								item.ConnectedCapability?.items[0]?.Capability
									.CapabilityConfig?.name
							}
						</Text>

						<Text
							fontFamily="$fontHeading"
							color={'$textDark1000'}
							fontSize={'$fontSize14'}
							fontWeight="$bold"
						>
							{item?.outcomes.items[0]?.Outcome
								? item?.outcomes.items[0].Outcome
								: item?.problem}
						</Text>
					</Box>
					{view === 'profile' && (
						<Tooltip
							placement="top"
							trigger={(triggerProps) => {
								return (
									<Pressable
										{...triggerProps}
										onPress={() => {
											!inModal &&
												navigation.push(
													'CapCreateHidi',
													{
														hidiId: item?.id,
														capabilityId:
															item
																.ConnectedCapability
																?.items[0]
																?.CapabilityId,
														capabilityName:
															item
																.ConnectedCapability
																?.items[0]
																?.Capability
																.CapabilityConfig
																?.name,
														capability:
															item
																.ConnectedCapability
																?.items[0]
																.Capability,
													}
												)
										}}
									>
										<Image
											source={require('../assets/Images/edit.png')}
											h={18}
											w={18}
										/>
									</Pressable>
								)
							}}
						>
							<TooltipContent bg={'$backgroundGray'}>
								<TooltipText
									fontFamily="$fontHeading"
									fontSize={12}
									color={'$fontGray100'}
									fontWeight="600"
								>
									Edit
								</TooltipText>
							</TooltipContent>
						</Tooltip>
					)}
				</HStack>
				{item.whereitwasdone && (
					<>
						<DataDisplay title={'While working as:'} />

						<Text
							fontFamily="$fontHeading"
							color={'$textDark1000'}
							fontSize={'$fontSize14'}
							fontWeight="$bold"
						>
							{textOutside} <Text color={'$fontGray'}>@</Text>{' '}
							<Text
								fontFamily="$fontHeading"
								color={'$textDark1000'}
								fontSize={'$fontSize14'}
								// fontWeight="$bold"
							>
								{textInside}
							</Text>
						</Text>
					</>
				)}

				{skillsFound?.length > 0 && (
					<Box mt={10}>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 11}
							color={'$fontGray'}
							fontWeight="500"
						>
							Skills used:
						</Text>
						<HStack gap={10} flexWrap={'wrap'} my={10}>
							{skillsFound?.map((skill) => {
								return (
									<Box
										borderWidth={1}
										px={6}
										borderRadius={10}
										borderColor={'$coolGray300'}
										display={
											skill.Skill?.Skill ? 'flex' : 'none'
										}
										// bg="#D3D3D3"
									>
										<Text
											fontFamily="$fontHeading"
											color={'$coolGray500'}
										>
											{skill?.Skill?.Skill}
										</Text>
									</Box>
								)
							})}
						</HStack>
					</Box>
				)}

				{item?.experience && (
					<DataDisplay
						text={item?.experience}
						title={'The Process of How i Solved It:'}
					/>
				)}
				<Box>
					<Text
						color="$primary500"
						fontFamily="$fontHeading"
						fontWeight="bold"
					>
						Read More ...
					</Text>
				</Box>
				<Box
					flexDirection={windowWidth > 480 ? 'row' : 'column'}
					marginVertical={10}
				>
					{typeOfUser === 'companys' && (
						<Box flex={1} marginVertical={5}>
							<Pressable
								w={windowWidth > 480 ? 'fit-content' : '$full'}
								paddingHorizontal={20}
								borderWidth={2}
								borderColor="$primary500"
								borderRadius={10}
								alignItems="center"
								onPress={() => {
									OpenHidiPublicView(item?.id, PreferenceId)
								}}
							>
								<Text
									color="$primary500"
									fontSize={windowWidth > 480 ? 14 : 12}
									fontFamily="$fontHeading"
								>
									Ask a Question
								</Text>
							</Pressable>
						</Box>
					)}
					<HStack
						alignItems="center"
						marginVertical={5}
						justifyContent="space-between"
					>
						<HStack>
							<Image
								alignSelf="center"
								height={10}
								width={10}
								marginHorizontal={5}
								source={require('../assets/Images/peerIcon.png')}
							/>
							<Text color="#A0A0A0" fontFamily="$fontHeading">
								Peer Vouching {item?.peerEmails ? 1 : 0}
							</Text>
						</HStack>
						<HStack>
							<Image
								alignSelf="center"
								height={10}
								marginHorizontal={5}
								width={10}
								source={require('../assets/Images/questionIcon.png')}
							/>
							<Text color="#A0A0A0" fontFamily="$fontHeading">
								Questions {item?.ConvoOnHidi?.items?.length}
							</Text>
						</HStack>
						<HStack>
							<Image
								alignSelf="center"
								height={10}
								width={10}
								marginHorizontal={5}
								source={require('../assets/Images/linkiconprofile.png')}
							/>
							<Text color="#A0A0A0" fontFamily="$fontHeading">
								Links {item?.projectLink ? 1 : 0}
							</Text>
						</HStack>
					</HStack>
				</Box>
			</Pressable>
		)
	}

	

	
	return (
		<>
			<Box p={PreferenceId ? (windowWidth > 480 ? 10 : 5) : null}>
				<Box marginVertical={10} gap={5}>
					<Text
						fontFamily="$fontHeading"
						color="$primary950"
						fontSize={windowWidth > 480 ? 20 : 18}
						fontWeight={700}
					>
						{PreferenceId && unmatchedCapabilityClaimed?.length == undefined
							? 'Capabilities for the job'
							: unmatchedCapabilityClaimed?.length > 0
							? 'Other Capabilities'
							: 'Capabilities & Experiences'}
					</Text>
					{
						unmatchedCapabilityClaimed?.length > 0 ? (
							<Text
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 14 : 12}
								fontWeight="$thin"
								color="#424242"
							>
								{unmatchedCapabilityClaimed?.length} Capabilities Claimed • {evidences}{' '}
								{evidences?.length > 1 ? 'Experiences' : 'Experience'}
							</Text>
						) : (	<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 14 : 12}
							fontWeight="$thin"
							color="#424242"
						>
							{capabilitiesClaimedLength
								? capabilitiesClaimedLength
								: 0}{' '}
							Capabilities Claimed • {evidences}{' '}
							{evidences?.length > 1 ? 'Experiences' : 'Experience'}
						</Text>)
					}
				
				</Box>
				<Divider bg="$borderDark0" marginVertical={10} />
				{capabilities || unmatchedCapabilityClaimed ? (
					<>
						<Box
							h={
								expand
									? null
									: UnlockedCapability.length > 1 &&
									  capabilitiesWithoutEvidence.length +
											UnlockedCapability.length >
											6
									? 500
									: UnlockedCapability.length > 0 ||
									  capabilitiesWithoutEvidence.length +
											UnlockedCapability.length >
											4
									? 300
									: 200
							}
							overflow="hidden"
						>
							<Box>
								<FlatList
									data={UnlockedCapability}
									renderItem={renderItem}
									keyExtractor={(item) => item?.id}
								/>
							</Box>
							<Box marginVertical={5}>
								{capabilitiesWithoutEvidence?.map((item) => (
									<Text
										fontFamily="$fontHeading"
										color={item?.color}
										fontSize={windowWidth > 480 ? 15 : 12}
										key={item.key}
										marginVertical={
											windowWidth > 480 ? 5 : 2
										}
										fontWeight="bold"
									>
										{item?.name}
									</Text>
								))}
								{unmatchedCapabilityClaimed?.map((item) => (
									<Text
										fontFamily="$fontHeading"
										color={'$primary400'}
										fontSize={windowWidth > 480 ? 15 : 12}
										key={item.key}
										marginVertical={
											windowWidth > 480 ? 5 : 2
										}
										fontWeight="bold"
									>
										{item?.CapabilityConfig?.name										}
									</Text>
								))}
							</Box>
						</Box>
						<Divider bg="$borderDark0" />
						<Pressable
							onPress={() => {
								setExpand(!expand)
							}}
							marginTop={windowWidth > 480 ? 20 : 10}
						>
							<Text
								textAlign="center"
								color="$blue500"
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 15 : 13}
								fontWeight="700"
							>
								{UnlockedCapability.length > 0 ||
								capabilitiesWithoutEvidence.length +
									UnlockedCapability.length >
									4
									? expand
										? 'Hide Capabilities'
										: 'View All Capabilities'
									: null}
							</Text>
						</Pressable>
					</>
				) : (
					<Box alignItems="center" justifyContent="center" h={300}>
						<SpinnerComponent smallText={'Loading...'} />
					</Box>
				)}
			</Box>
		</>
	)
}
