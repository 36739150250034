export const getProfileByUserId = /* GraphQL */ `
	query GetProfileByUserId(
		$UserId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelProfileFilterInput
		$limit: Int
		$nextToken: String
	) {
		getProfileByUserId(
			UserId: $UserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				name
				UserId
				Email
				id
				about
				hidis {
					items {
						createdAt
						Email
						id
						MobileNumber
						name
						UserId
						PSStitle
						PSSdescription
						DMStitle

						DMSdescription
						CTtitle
						CTdescription
						Atitle
						Adescription
						Verified
						updatedAt
						owner
					}
					nextToken
				}

				updatedAt
				resume
				education
				experience
				expertise
				graduation
				owner
			}
			nextToken
		}
	}
`
export const getNewprofileByUserIdForCompany = /* GraphQL */ `
	query GetNewprofileByUserId(
		$UserId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelNewprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		getNewprofileByUserId(
			UserId: $UserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				name
				UserId
				Email
				id
				phone
				about
				hidis {
					items {
						createdAt
						Email
						id
						profileId
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						name
						UserId
						outcomes {
							items {
								createdAt
								id
								Outcome
								HidiId
								Verified
								updatedAt
								owner
							}
						}
						summary
						description
						title
						Verified
						likes
						views
						steps
						jobs
						outcomelink
						impact
						impactOverall
						outcome
						pinnedFlag
						tag1
						tag2
						htmlheading
						htmlsummary
						htmloutput
						outcomejson
						updatedAt
						owner
					}
					nextToken
				}
				Timeline {
					items {
						createdAt
						UserId
						profileId
						description
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						id
						Timelinestring
						updatedAt
						owner
					}
					nextToken
				}
				comapanyshortlists {
					items {
						createdAt
						businessProfileId
						businessUserId
						UserId
						about
						candidateprofileId
						shortlistedbyId
						shortlistedByUser {
							createdAt
							username
							businessProfileId
							id
							cognitoId
							updatedAt
							owner
						}
						recommededId
						preferencesId
						prferenceType {
							createdAt
							id
							rolename
							businessUserId
							businessProfileId
							package
							certifications
							noticeperiod
							modeofwork
							other
							previousrole
							combinedrole
							updatedAt
							owner
						}
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						id
						candidateprofile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						businessProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
						}
						updatedAt
						owner
					}
					nextToken
				}
				comapanyrejected {
					items {
						createdAt
						businessProfileId
						businessUserId
						shortlistedbyId
						shortlistedByUser {
							createdAt
							username
							businessProfileId
							id
							cognitoId
							updatedAt
							owner
						}
						UserId
						about
						candidateprofileId
						Reasonofrejection
						recommededId
						preferencesId
						prferenceType {
							createdAt
							id
							rolename
							businessUserId
							businessProfileId
							package
							certifications
							noticeperiod
							modeofwork
							other
							previousrole
							combinedrole
							updatedAt
							owner
						}
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						id
						candidateprofile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						businessProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
						}
						updatedAt
						owner
					}
					nextToken
				}
				inpreference {
					items {
						createdAt
						businessProfileId
						businessUserId
						UserId
						candidateprofileId
						acceptanceStatus
						Reasonofrejection
						prferenceType {
							createdAt
							id
							rolename
							businessUserId
							businessProfileId
							package
							certifications
							noticeperiod
							modeofwork
							other
							previousrole
							combinedrole
							updatedAt
							owner
						}
						preferencesId
						candidateprofile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						businessProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
						}
						new
						id
						updatedAt
						owner
					}
					nextToken
				}
				Skills {
					items {
						createdAt
						profileId
						UserId
						description
						id
						Skill
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						updatedAt
						owner
					}
					nextToken
				}
				profileFeedback {
					items {
						createdAt
						id
						profileId
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						name
						designation
						description
						UserId
						updatedAt
						owner
					}
					nextToken
				}
				certifications {
					items {
						createdAt
						id
						profileId
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							imagekey
							updatedAt
							owner
						}
						Certificatename
						description
						certificateId
						validity
						UserId
						updatedAt
						owner
					}
					nextToken
				}
				salarycurrent
				salaryexpectation
				certification
				noticeperiod
				currentoffer
				education
				experience
				expertise
				graduation
				image
				resume
				location
				imageicon1
				link1
				imageicon2
				link2
				imageicon3
				link3
				profileviews
				relevant
				connected
				imagekey
				updatedAt
				owner
			}
			nextToken
		}
	}
`
export const customlistshortlistsByPreferencesByDate = /* GraphQL */ `
	query ListshortlistsByPreferencesByDate(
		$preferencesId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelshortlistFilterInput
		$limit: Int
		$nextToken: String
	) {
		listshortlistsByPreferencesByDate(
			preferencesId: $preferencesId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				businessUserId
				UserId
				about
				candidateprofileId
				prferenceType {
					createdAt
					id
					rolename
					businessUserId
					recommendedprofiles {
						nextToken
					}
					shortlistedProfiles {
						nextToken
					}
					package
					certifications
					noticeperiod
					modeofwork
					other
					previousrole
					combinedrole
					updatedAt
					owner
				}
				preferencesId
				profile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about
					hidis {
						nextToken
					}
					Timeline {
						nextToken
					}
					comapanyshortlists {
						nextToken
					}
					inpreference {
						items {
							createdAt
							businessProfileId
							businessUserId
							UserId
							candidateprofileId
							acceptanceStatus
							Reasonofrejection
							preferencesId
							id
							updatedAt
							owner
						}
						nextToken
					}
					salarycurrent
					salaryexpectation
					certification
					noticeperiod
					currentoffer
					education
					experience
					expertise
					graduation
					image
					resume
					location
					imageicon1
					link1
					imageicon2
					link2
					imageicon3
					link3
					updatedAt
					owner
				}
				businessProfile {
					createdAt
					name
					businessUserId
					id
					preferences
					about
					Shortlists {
						nextToken
					}
					prefferedprofile {
						nextToken
					}
					updatedAt
					owner
				}
				id
				updatedAt
				owner
			}
			nextToken
		}
	}
`

export const getbusinessprofileeBybusinessUserId = /* GraphQL */ `
	query GetbusinessprofileeBybusinessUserId(
		$businessUserId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelcompanyprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		getbusinessprofileeBybusinessUserId(
			businessUserId: $businessUserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				name
				businessUserId
				id
				preferences
				about
				Shortlists {
					items {
						createdAt
						businessProfileId
						businessUserId
						UserId
						about
						candidateprofileId
						recommededId
						preferencesId
						prferenceType {
							createdAt
							id
							rolename
							businessUserId
							package
							certifications
							noticeperiod
							modeofwork
							other
							previousrole
							combinedrole
							updatedAt
							owner
						}
						profile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							updatedAt
							owner
						}
						id
						candidateprofile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							updatedAt
							owner
						}
						businessProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
						}
						updatedAt
						owner
					}
					nextToken
				}
				prefferedprofile {
					items {
						createdAt
						businessProfileId
						businessUserId
						UserId
						candidateprofileId
						acceptanceStatus
						Reasonofrejection
						prferenceType {
							createdAt
							id
							rolename
							businessUserId
							package
							certifications
							noticeperiod
							modeofwork
							other
							previousrole
							combinedrole
							updatedAt
							owner
						}
						preferencesId
						candidateprofile {
							createdAt
							name
							UserId
							Email
							id
							phone
							about
							salarycurrent
							salaryexpectation
							certification
							noticeperiod
							currentoffer
							education
							experience
							expertise
							graduation
							image
							resume
							location
							imageicon1
							link1
							imageicon2
							link2
							imageicon3
							link3
							profileviews
							relevant
							connected
							updatedAt
							owner
						}
						businessProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
						}
						id
						updatedAt
						owner
					}
					nextToken
				}
				preferenceslist {
					items {
						createdAt
						id
						rolename
						businessUserId
						recommendedprofiles {
							items {
								createdAt
								businessProfileId
								businessUserId
								UserId
								candidateprofileId
								acceptanceStatus
								Reasonofrejection
							}
							nextToken
						}
						shortlistedProfiles {
							items {
								createdAt
								businessProfileId
								businessUserId
								UserId
								candidateprofileId
							}
							nextToken
						}
						package
						certifications
						noticeperiod
						modeofwork
						other
						previousrole
						combinedrole
						updatedAt
						owner
					}
					nextToken
				}
				updatedAt
				owner
			}
			nextToken
		}
	}
`
export const getcompanyusersbycognitoId = /* GraphQL */ `
	query GetcompanyusersbycognitoId(
		$cognitoId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelcompanyusersFilterInput
		$limit: Int
		$nextToken: String
	) {
		getcompanyusersbycognitoId(
			cognitoId: $cognitoId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				username
				businessProfileId
				id
				cognitoId
				companyprofile {
					createdAt
					name
					businessUserId
					id
					preferences
					about
					preferenceslist {
						items {
							createdAt
							id
							rolename
							businessUserId
							JobPosts {
								items {
									id
								}
							}
							companyQrewId
							businessProfileId
							package
							maxExperienceRequired
							minExperienceRequired
							certifications
							noticeperiod
							modeofwork
							other
							previousrole
							combinedrole
							JobPublished
							recommendedprofiles {
								items {
									createdAt
									businessProfileId
									businessUserId
									UserId
									candidateprofileId
									acceptanceStatus
									Reasonofrejection
								}
								nextToken
							}
							shortlistedProfiles {
								items {
									createdAt
									businessProfileId
									businessUserId
									UserId
									candidateprofileId
								}
								nextToken
							}
							updatedAt
							owner
						}
						nextToken
					}
					updatedAt
					owner
				}
				updatedAt
				owner
			}
			nextToken
		}
	}
`
export const listCapabilitiesByProfileIdByDate = /* GraphQL */ `
	query ListCapabilitiesByProfileIdByDate(
		$ProfileId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelCapabilitiesFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCapabilitiesByProfileIdByDate(
			ProfileId: $ProfileId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				ProfileId
				id
				userProfile {
					createdAt
					name
					ConsentToShareInfo
				}
				JoinedSkills {
					items {
						id
						UserId
						SkillId
						CapabilityId
						Skill {
							createdAt
							profileId
							UserId
							id
							YearsOfExperience
							Skill
							updatedAt
							owner
							__typename
						}
						GlobalCapabilityConfigId
						GlobalCapabilityConfigName
						createdAt
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				ConnectedHidis {
					items {
						CapabilityId
						HidiId
						Capability {
							createdAt
							ProfileId
							id
							updatedAt
							capabilitiesCapabilityConfigId
							owner
							__typename
						}
						Hidi {
							createdAt
							Email
							id
							profileId
							name
							UserId
							summary
							description
							title
							outcomes {
								items {
									createdAt
									id
									Outcome
									HidiId
									Verified
									updatedAt
									owner
									__typename
								}
							}
							challenges {
								items {
									createdAt
									id
									challenge
									HidiId
									updatedAt
									owner
									__typename
								}
								nextToken
								__typename
							}
							ConvoOnHidi {
								items {
									id
									Question
									Answer
									profileId
									UserId

									PreferenceId
									companyProfileId

									companyUserProfileId
									HidiId
									createdAt
									updatedAt
									owner
									__typename
								}
								nextToken
								__typename
							}
							impact
							impactOverall
							outcome
							pinnedFlag
							tag1
							tag2
							htmlheading
							htmlsummary
							htmloutput
							outcomejson
							Hack
							experience
							helped
							ProbabilityFake
							ProbabilityReal
							whereitwasdone

							AiGenerated
							problem
							hidiTopic
							updatedAt
							owner
							__typename
						}
						createdAt
						id
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				CapabilityConfig {
					id
					name
					tag
					domain
					capabilitiesClaimed {
						items {
							id
						}
						nextToken
						__typename
					}
					createdAt
					updatedAt
					CapabilityPoints
					owner
					__typename
				}
				updatedAt
				capabilitiesCapabilityConfigId

				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`

export const getNewprofileByUserId = /* GraphQL */ `
	query GetNewprofileByUserId(
		$UserId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelNewprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		getNewprofileByUserId(
			UserId: $UserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				name
				UserId
				Email
				id
				phone
				about
				IntroVideo
				deployedforqrews {
					items{
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
			preferencesId
            id
            owner
            __typename
						companyQrew {
					id
					qrewname
				}
					
						
						JobPostPreferences {
							__typename
							rolename
							companyprofile {
								name
								
									id
							}
						
				}
				}
      }
				hidis {
					items {
						createdAt
						Email
						id
						profileId
						name
						UserId
						outcomes {
							items {
								createdAt
								id
								Outcome
								HidiId
								Verified
								updatedAt
								owner
								__typename
							}
						}
						challenges {
							items {
								createdAt
								id
								challenge
								HidiId
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						ConvoOnHidi {
							items {
								id
								Question
								Answer
								profileId
								UserId

								PreferenceId
								companyProfileId

								companyUserProfileId
								HidiId
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}

						ConnectedCapability {
							items {
								CapabilityId
								HidiId
								id
								Capability {
									CapabilityConfig {
										domain
										name
										weeklyActiveOpportunity
										tag
										id
									}
									JoinedSkills {
										items {
											id
											UserId
											SkillId
											CapabilityId
											Skill {
												createdAt
												profileId
												UserId
												id
												YearsOfExperience
												Skill
												updatedAt
												owner
												__typename
											}
											GlobalCapabilityConfigId
											GlobalCapabilityConfigName
											createdAt
											updatedAt
											owner
											__typename
										}
										nextToken
										__typename
									}
								}
							}
							nextToken
							__typename
						}

						summary
						description
						title
						Verified
						likes
						views
						steps
						jobs
						outcomelink
						impact
						impactOverall
						imagelink
						outcome
						pinnedFlag
						tag1
						tag2
						htmlheading
						htmlsummary
						Hack
						experience
						helped
						problem
						ProbabilityFake
						ProbabilityReal
						AiGenerated
						htmloutput

						whereitwasdone
						outcomejson
						hidiTopic
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				CandidatePreferences {
					items {
						createdAt
						id
						rolename
						jobType
						workType
						rolelevel
						benefits
						priority
						expectation
						Salaryexpectation
						currentSalary
						companyType
						workStyle
						minimumContractPeriod
						servingNotice
						noticePeriod
						workSchedule
						Activelylooking
						contractType
						prevContractCount
						compensationStructure
						AvailableDate
						CurrencyCountry
						Country
						ActivatePreference
						ContractCompensation
						userDomain
						roletype
						location1
						location2
						location3
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				Capabilities {
					items {
						createdAt
						ProfileId
						id
						ConnectedHidis {
							items {
								id
							}

							nextToken
							__typename
						}
						CapabilityConfig {
							id
							name
							tag
							domain
							createdAt
							CapabilityPoints
							updatedAt
							owner
							__typename
						}
						JoinedSkills {
							items {
								id
								Skill {
									id
									Skill
								}
							}
							nextToken
							__typename
						}

						updatedAt

						capabilitiesCapabilityConfigId
						owner
						__typename
					}
					nextToken
					__typename
				}
				salarycurrent
				salaryexpectation
				certification
				noticeperiod
				currentoffer
				education
				experience
				expertise
				graduation
				image
				resume
				location
				imageicon1
				link1
				imageicon2
				link2
				imageicon3
				link3
				profileviews
				relevant
				connected
				imagekey
				typeofwork
				resumekey
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const getNewprofileByUserIdForProfile = /* GraphQL */ `
	query GetNewprofileByUserId(
		$UserId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelNewprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		getNewprofileByUserId(
			UserId: $UserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				name
				UserId
				Email
				id
				phone
				about
				IntroVideo
				deployedforqrews {
					items{
						id
						companyQrewId
						businessProfileId
						qrewPosition
						recommededId
						JobApplicationsId
						ExtensionDurationInMonths 
						ExtensionDate 	
						ExtendingContract 
						companyQrewId
						businessProfileId
						qrewPosition
						recommededId
						JobApplicationsId
						ExtensionDurationInMonths 
						ExtensionDate 	
						ExtendingContract 
						ExtensionDurationInMonths 
						ExtensionDate 	
						ExtendingContract 
						CandidateAcceptanceStatus 
						NewContractCTC 
						companyQrew {
					id
					qrewname
				}
						CandidateAcceptanceStatus 
				NewContractCTC 
						joiningDate
						endingDate
						EndContract
						ContractEndingDate
						JobPostPreferences {
							__typename
							rolename
							companyprofile {
								name
								
									id
							}
						
				}
				}
      }
				hidis {
					items {
						createdAt
						Email
						id
						profileId
						name
						UserId
						outcomes {
							items {
								createdAt
								id
								Outcome
								HidiId
								Verified
								updatedAt
								owner
								__typename
							}
						}
						challenges {
							items {
								createdAt
								id
								challenge
								HidiId
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						ConvoOnHidi {
							items {
								id
								Question
								Answer
								profileId
								UserId

								PreferenceId
								companyProfileId

								companyUserProfileId
								HidiId
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}

						ConnectedCapability {
							items {
								CapabilityId
								HidiId
								id
								Capability {
									CapabilityConfig {
										domain
										name
										weeklyActiveOpportunity
										tag
										id
									}
									JoinedSkills {
										items {
											id
											UserId
											SkillId
											CapabilityId
											Skill {
												createdAt
												profileId
												UserId
												id
												YearsOfExperience
												Skill
												updatedAt
												owner
												__typename
											}
											GlobalCapabilityConfigId
											GlobalCapabilityConfigName
											createdAt
											updatedAt
											owner
											__typename
										}
										nextToken
										__typename
									}
								}
							}
							nextToken
							__typename
						}

						summary
						description
						title
						Verified
						likes
						views
						steps
						jobs
						outcomelink
						impact
						impactOverall
						imagelink
						outcome
						pinnedFlag
						tag1
						tag2
						htmlheading
						htmlsummary
						Hack
						experience
						helped
						problem
						ProbabilityFake
						ProbabilityReal
						AiGenerated
						htmloutput

						whereitwasdone
						outcomejson
						hidiTopic
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				CandidatePreferences {
					items {
						createdAt
						id
						rolename
						jobType
						workType
						rolelevel
						benefits
						priority
						expectation
						Salaryexpectation
						currentSalary
						companyType
						workStyle
						minimumContractPeriod
						servingNotice
						noticePeriod
						workSchedule
						Activelylooking
						contractType
						prevContractCount
						compensationStructure
						AvailableDate
						CurrencyCountry
						Country
						ActivatePreference
						ContractCompensation
						userDomain
						roletype
						location1
						location2
						location3
						HourlyRateExpectedOnContract
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				Capabilities {
					items {
						createdAt
						ProfileId
						id
						ConnectedHidis {
							items {
								id
							}

							nextToken
							__typename
						}
						CapabilityConfig {
							id
							name
							tag
							domain
							createdAt
							CapabilityPoints
							updatedAt
							owner
							__typename
						}
						JoinedSkills {
							items {
								id
								Skill {
									id
									Skill
								}
							}
							nextToken
							__typename
						}

						updatedAt

						capabilitiesCapabilityConfigId
						owner
						__typename
					}
					nextToken
					__typename
				}
				salarycurrent
				salaryexpectation
				certification
				noticeperiod
				currentoffer
				education
				experience
				expertise
				graduation
				image
				resume
				location
				imageicon1
				link1
				imageicon2
				link2
				imageicon3
				link3
				profileviews
				relevant
				connected
				imagekey
				typeofwork
				resumekey
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}`

export const list_Feed_by_UserSegmentId = /* GraphQL */ `
	query List_Feed_by_UserSegmentId(
		$UserSegmentId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelFeedFilterInput
		$limit: Int
		$nextToken: String
	) {
		list_Feed_by_UserSegmentId(
			UserSegmentId: $UserSegmentId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				UserSegmentId
				HidiId
				Hidi {
					createdAt
					Email
					id
					profileId
					profile {
						createdAt
						name
						UserId
						Email
						id
						phone
						about
						ActivatedCandisatePreferencesID
						salarycurrent
						salaryexpectation
						certification
						noticeperiod
						currentoffer
						education
						experience
						expertise
						graduation
						image
						resume
						location
						imageicon1
						link1
						imageicon2
						link2
						imageicon3
						link3
						profileviews
						relevant
						connected
						imagekey
						typeofwork
						resumekey
						updatedAt
						owner
						__typename
					}
					name
					UserId
					outcomes {
						items {
							createdAt
							id
							Outcome
							HidiId
							Verified
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}

					ConnectedCapability {
						items {
							CapabilityId
							HidiId
							id
							Capability {
								createdAt
								ProfileId
								id
								capabilitiesCapabilityConfigId
								CapabilityConfig {
									id
									name
									tag
									domain
									createdAt
									weeklyActiveOpportunity
									capabilitiesClaimedCount
									ActivityStatus
									updatedAt
									owner
									__typename
								}
								nextToken
								updatedAt
								owner
								__typename
							}
						}
					}
					summary
					description
					title
					Verified
					likes
					views
					steps
					jobs
					outcomelink
					impact
					impactOverall
					imagelink
					outcome
					pinnedFlag
					tag1
					tag2
					htmlheading
					htmlsummary
					htmloutput
					outcomejson
					hidiTopic
					updatedAt
					owner
					__typename
				}
				CapabilityConfigId
				jobPostId
				jobPost {
					id
					CompanyPreferenceId
					CompanyPreference {
						package
						certifications
						currencyCountry
						noticeperiod
						modeofwork
						other
						previousrole
						combinedrole
						businessProfileId
						rolename
						Location
						JobType
					}
					companyName
					jobPostLink
					description
					CompanyDescription
					title
					CapabilitiesRequired {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID
							createdAt
							CapabilityConfig {
								domain
								name
								weeklyActiveOpportunity
								tag
								updatedAt
								id
							}
						}
						nextToken
						__typename
					}

					createdAt
					updatedAt
					owner
					__typename
				}
				CapabilityConfig {
					id
					name
					tag
					domain
					weeklyActiveOpportunity
					createdAt
					capabilitiesClaimed {
						items {
							createdAt
							ProfileId
							userProfile {
								createdAt
								name
								UserId
								Email
								id
								phone
								about
								ActivatedCandisatePreferencesID
								salarycurrent
								salaryexpectation
								certification
								noticeperiod
								currentoffer
								education
								experience
								expertise
								graduation
								image
								resume
								location
								imageicon1
								link1
								imageicon2
								link2
								imageicon3
								link3
								profileviews
								relevant
								connected
								imagekey
							}
							id
							capabilitiesCapabilityConfigId
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}

					connectedJobPosts {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID

							createdAt
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}
					updatedAt
					owner
					__typename
				}
				Description
				Payload
				FeedType
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const list_Feed_by_Domain = /* GraphQL */ `
	query List_Feed_by_Domain(
		$domain: String!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelFeedFilterInput
		$limit: Int
		$nextToken: String
	) {
		list_Feed_by_Domain(
			domain: $domain
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				UserSegmentId
				HidiId
				Hidi {
					createdAt
					Email
					id
					profileId
					profile {
						createdAt
						name
						UserId
						Email
						id
						phone
						about
						ActivatedCandisatePreferencesID
						salarycurrent
						salaryexpectation
						certification
						noticeperiod
						currentoffer
						education
						experience
						expertise
						graduation
						image
						resume
						location
						imageicon1
						link1
						imageicon2
						link2
						imageicon3
						link3
						profileviews
						relevant
						connected
						imagekey
						typeofwork
						resumekey
						updatedAt
						owner
						__typename
					}
					name
					UserId
					outcomes {
						items {
							createdAt
							id
							Outcome
							HidiId
							Verified
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}
					ConnectedCapability {
						items {
							CapabilityId
							HidiId
							id
							Capability {
								createdAt
								ProfileId
								id
								capabilitiesCapabilityConfigId
								CapabilityConfig {
									id
									name
									tag
									domain
									createdAt
									weeklyActiveOpportunity
									capabilitiesClaimedCount
									ActivityStatus
									updatedAt
									owner
									__typename
								}
								nextToken
								updatedAt
								owner
								__typename
							}
						}
					}

					summary
					description
					title
					Verified
					likes
					views
					steps
					jobs
					outcomelink
					impact
					impactOverall
					imagelink
					outcome
					pinnedFlag
					tag1
					tag2
					htmlheading
					htmlsummary
					htmloutput
					outcomejson
					hidiTopic
					updatedAt
					owner
					__typename
				}
				CapabilityConfigId
				jobPostId
				jobPost {
					id
					CompanyPreferenceId
					CompanyPreference {
						package
						certifications
						noticeperiod
						modeofwork
						other
						previousrole
						combinedrole
						currencyCountry
						businessProfileId
						rolename
						Location
						JobType
					}
					companyName
					jobPostLink
					description
					CompanyDescription
					title
					CapabilitiesRequired {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID
							createdAt
							CapabilityConfig {
								domain
								name
								CapabilityPoints
								weeklyActiveOpportunity
								tag
								updatedAt
								id
							}
						}
						nextToken
						__typename
					}

					createdAt
					updatedAt
					owner
					__typename
				}
				CapabilityConfig {
					id
					name
					tag
					CapabilityPoints
					domain
					weeklyActiveOpportunity
					createdAt
					capabilitiesClaimed {
						items {
							createdAt
							ProfileId
							userProfile {
								createdAt
								name
								UserId
								Email
								id
								phone
								about
								ActivatedCandisatePreferencesID
								salarycurrent
								salaryexpectation
								certification
								noticeperiod
								currentoffer
								education
								experience
								expertise
								graduation
								image
								resume
								location
								imageicon1
								link1
								imageicon2
								link2
								imageicon3
								link3
								profileviews
								relevant
								connected
								imagekey
							}
							id
							capabilitiesCapabilityConfigId
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}

					connectedJobPosts {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID

							createdAt
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}
					updatedAt
					owner
					__typename
				}
				Description
				Payload
				FeedType
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listGlobalCapabilityConfigs = /* GraphQL */ `
	query ListGlobalCapabilityConfigs(
		$filter: ModelGlobalCapabilityConfigFilterInput
		$limit: Int
		$nextToken: String
	) {
		listGlobalCapabilityConfigs(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
				tag
				domain
				createdAt
				weeklyActiveOpportunity
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const Get_JobPost_by_companyPreferenceId = /* GraphQL */ `
	query Get_JobPost_by_companyPreferenceId(
		$CompanyPreferenceId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelJobPostFilterInput
		$limit: Int
		$nextToken: String
	) {
		Get_JobPost_by_companyPreferenceId(
			CompanyPreferenceId: $CompanyPreferenceId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				CompanyPreferenceId
				CompanyPreference {
					createdAt
					id
					rolename
					businessUserId
					businessProfileId
					package
					currencyCountry
					certifications
					noticeperiod
					companyprofile {
						createdAt
						businessUserId
						name
						companyMailId
						id
						preferences
						about
						websiteUrl
						LogoImageKey
						IndustryType
						updatedAt
						owner
          				__typename
						}
					maxExperienceRequired
					minExperienceRequired
					modeofwork
					other
					previousrole
					combinedrole
					JobType
					Location
					updatedAt
					owner
					__typename
				}
				companyName
				jobPostLink
				logoUrl
				htmldescription
				CompanyDescription
				RolesandResponsibility
				AvailablityStatus
				experienceRequired
				jobType
				Location
				skills
				domain
				description
				ShortDesc
				title
				CapabilitiesRequired {
					items {
						id
						jobPostID
						GlobalCapabilityConfigID
						CapabilityConfig {
							id
							name
							tag
							domain
							createdAt
							weeklyActiveOpportunity
							capabilitiesClaimedCount
							CapabilityPoints
							ActivityStatus
							updatedAt
							owner
							__typename
						}
						createdAt
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}

				CapabilitiesRequiredJSON
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const list_globalcapbilities_by_ActivityStatus_filer_by_domain = /* GraphQL */ `
	query List_globalcapbilities_by_ActivityStatus_filer_by_domain(
		$ActivityStatus: CapabilityActivityStatus!
		$domain: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelGlobalCapabilityConfigFilterInput
		$limit: Int
		$nextToken: String
	) {
		list_globalcapbilities_by_ActivityStatus_filer_by_domain(
			ActivityStatus: $ActivityStatus
			domain: $domain
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				name
				tag
				domain
				createdAt
				weeklyActiveOpportunity
				capabilitiesClaimed {
					items {
						id
						userProfile {
							id
							name
							UserId
						}
					}
					nextToken
					__typename
				}
				ActivityStatus
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`

export const getNewprofileByUserIdForDashboardInsights = /* GraphQL */ `
	query GetNewprofileByUserId(
		$UserId: ID!
		$sortDirection: ModelSortDirection
		$filter: ModelNewprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		getNewprofileByUserId(
			UserId: $UserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				name
				UserId
				Email
				
				id
				phone
				about
				IntroVideo
				CandidatePreferences {
					items {
						createdAt
						id
						rolename
						jobType
						workType
						rolelevel
						benefits
						priority
						expectation
						Salaryexpectation
						currentSalary
						companyType
						workStyle
						minimumContractPeriod
						servingNotice
						noticePeriod
						workSchedule
						Activelylooking
						contractType
						Country
						CurrencyCountry
						compensationStructure
						AvailableDate
						ContractCompensation
						HourlyRateExpectedOnContract
						userDomain
						roletype
						location1
						location2
						location3
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				comapanyshortlists {
					items {
						createdAt
						businessProfileId
						businessUserId
						UserId
						about
						candidateprofileId
						shortlistedbyId
						recommededId
						preferencesId
						id
						businessProfile {
							createdAt
							name
							businessUserId
							id
							preferences
							about
							updatedAt
							owner
							__typename
						}
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				hidis {
					items {
						peerEmails
						projectLink
						problem
						createdAt
						MeatFoundbyAI
						MeatFoundbyHuman
						AiAnalysisForMeat 
						Email
						id
						profileId
						name
						UserId
						challenges {
							items {
								createdAt
								id
								challenge
								HidiId
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						outcomes {
							items {
								createdAt
								id
								Outcome
								HidiId
								Verified
								updatedAt
								owner
								__typename
							}
						}
						ConvoOnHidi {
							items {
								id
								Question
								Answer
								profileId
								UserId

								PreferenceId
								companyProfileId

								companyUserProfileId
								HidiId
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						ConnectedCapability {
							items {
								CapabilityId
								HidiId
								Capability {
									createdAt
									ProfileId
									id
									capabilitiesCapabilityConfigId
									CapabilityConfig {
										id
										name
										tag
										domain
										createdAt
										weeklyActiveOpportunity
										capabilitiesClaimedCount

										ActivityStatus
										updatedAt
										owner
										__typename
									}
									JoinedSkills {
										items {
											id
											UserId
											SkillId
											CapabilityId
											Skill {
												createdAt
												profileId
												UserId
												id
												YearsOfExperience
												Skill
												updatedAt
												owner
												__typename
											}
											GlobalCapabilityConfigId
											GlobalCapabilityConfigName
											createdAt
											updatedAt
											owner
											__typename
										}
										nextToken
										__typename
									}
									updatedAt
									owner
									__typename
								}
							}
						}
						summary
						description
						title
						Verified
						likes
						views
						steps
						jobs
						outcomelink
						impact
						impactOverall
						imagelink
						outcome
						pinnedFlag
						tag1
						tag2
						htmlheading
						htmlsummary
						htmloutput

						whereitwasdone
						whereitwasdonecompany
						Hack
						experience
						ProbabilityFake
						ProbabilityReal
						AiGenerated
						helped
						outcomejson
						hidiTopic
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				Capabilities {
					items {
						createdAt
						ProfileId
						id
						ConnectedHidis {
							items {
								id
							}

							nextToken
							__typename
						}
						CapabilityConfig {
							id
							name
							tag
							domain
							createdAt
							updatedAt
							owner
							__typename
						}
						updatedAt
						capabilitiesCapabilityConfigId
						owner
						__typename
					}
					nextToken
					__typename
				}
				salarycurrent
				salaryexpectation
				certification
				noticeperiod
				currentoffer
				education
				experience
				expertise
				graduation
				image
				resume
				location
				imageicon1
				link1
				imageicon2
				link2
				imageicon3
				link3
				profileviews
				relevant
				connected
				imagekey
				typeofwork
				resumekey
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const getNewhidi = /* GraphQL */ `
	query GetNewhidi($id: ID!) {
		getNewhidi(id: $id) {
			createdAt
			Email
			id
			profileId
			MeatFoundbyAI
			MeatFoundbyHuman
			AiAnalysisForMeat
			profile {
				createdAt
				name
				UserId
				Email
				id
				phone
				about
				salarycurrent
				salaryexpectation
				certification
				noticeperiod
				currentoffer
				education
				experience
				expertise
				graduation
				image
				resume
				location
				imageicon1
				link1
				imageicon2
				link2
				imageicon3
				link3
				profileviews
				relevant
				connected
				imagekey
				typeofwork
				resumekey
				updatedAt
				owner
				__typename
			}
			name
			UserId
			outcomes {
				items {
					createdAt
					id
					Outcome
					HidiId
					Verified
					updatedAt
					owner
					__typename
				}
				nextToken
				__typename
			}
			ConnectedCapability {
				items {
					CapabilityId
					HidiId
					Capability {
						createdAt
						ProfileId
						capabilitiesCapabilityConfigId
						CapabilityConfig {
							id
							name
							tag
							domain
							createdAt
							weeklyActiveOpportunity
							updatedAt
							owner
							__typename
						}
						JoinedSkills {
							items {
								id
								UserId
								SkillId
								CapabilityId
								Skill {
									createdAt
									profileId
									UserId
									id
									Skill
									updatedAt
									owner
									__typename
								}
								GlobalCapabilityConfigId
								GlobalCapabilityConfigName
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						updatedAt
						owner
						__typename
					}

					createdAt
					id
					updatedAt
					owner
					__typename
				}
				nextToken
				__typename
			}
			ConvoOnHidi {
				items {
					id
					Question
					Answer
					profileId
					UserId

					PreferenceId
					companyProfileId
					CompanyProfile {
						createdAt
						name
						businessUserId
						id
						preferences
						about

						updatedAt
						owner
						__typename
					}
					companyUser {
						createdAt
						username
						businessProfileId
						id
						cognitoId
						email
						updatedAt
						owner
						__typename
					}
					companyUserProfileId
					HidiId
					createdAt
					updatedAt
					owner
					__typename
				}
				nextToken
				__typename
			}
			challenges {
				items {
					createdAt
					id
					challenge
					HidiId
					updatedAt
					owner
					__typename
				}
				nextToken
				__typename
			}

			summary
			description
			title
			Verified
			likes
			views
			steps
			jobs
			outcomelink
			impact
			impactOverall
			imagelink
			outcome
			pinnedFlag
			tag1
			tag2
			htmlheading
			htmlsummary

			whereitwasdone
			whereitwasdonecompany
			htmloutput
			peerEmails
			projectLink
			outcomejson
			Hack
			experience
			helped
			ProbabilityFake
			ProbabilityReal
			AiGenerated
			problem
			hidiTopic
			updatedAt
			owner
			__typename
		}
	}
`

export const list_JobApplicants_by_companyPreferenceId = /* GraphQL */ `
	query List_JobApplicants_by_companyPreferenceId(
		$CompanyPreferenceId: ID!
		$ApplyingStatus: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelJobApplicationsFilterInput
		$limit: Int
		$nextToken: String
	) {
		list_JobApplicants_by_companyPreferenceId(
			CompanyPreferenceId: $CompanyPreferenceId
			ApplyingStatus: $ApplyingStatus
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				JobPostId
				CompanyProfileId
				ProfileId
				CompanyPreferenceId
				candidateprofile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about
					IntroVideo
					Capabilities {
						items {
							createdAt
							ProfileId
							id
							capabilitiesCapabilityConfigId
							ConnectedHidis {
								items {
									id
								}
							}
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}
				}
				ApplyingStatus
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const list_JobApplicants_by_Level_sortbyCompanyprefernceId = /* GraphQL */ `
	query List_JobApplicants_by_Level_sortbyCompanyprefernceId(
		$Level: Int!
		$CompanyPreferenceId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelJobApplicationsFilterInput
		$limit: Int
		$nextToken: String
	) {
		list_JobApplicants_by_Level_sortbyCompanyprefernceId(
			Level: $Level
			CompanyPreferenceId: $CompanyPreferenceId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				JobPostId
				CompanyProfileId
				ProfileId
				CompanyPreferenceId

				candidateprofile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about
					IntroVideo
					Capabilities {
						items {
							createdAt
							ProfileId
							id
							capabilitiesCapabilityConfigId
							ConnectedHidis {
								items {
									id
								}
							}
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}
				}
				ApplyingStatus
				Level
				CandidateProfileAnalysis
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listCapabilitiesByProfileAndJobConnected = /* GraphQL */ `
	query ListCapabilitiesByProfileIdByDate(
		$ProfileId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelCapabilitiesFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCapabilitiesByProfileIdByDate(
			ProfileId: $ProfileId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				ProfileId
				id

				capabilitiesCapabilityConfigId
				CapabilityConfig {
					id
					name
					tag
					domain
					createdAt
					weeklyActiveOpportunity
					connectedJobPosts {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID
							jobPost {
								id
								CompanyPreferenceId
								companyName
								jobPostLink
								htmldescription
								CompanyDescription
								AvailablityStatus
								experienceRequired
								Location
								skills
								domain
								description
								ShortDesc
								title
								createdAt
								updatedAt
							}
							createdAt
							updatedAt
						}
						nextToken
					}
					ActivityStatus
					updatedAt
				}
				updatedAt
			}
			nextToken
		}
	}
`

export const listRejectedByPreferencesByDate = /* GraphQL */ `
	query ListRejectedByPreferencesByDate(
		$preferencesId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelRejectedFilterInput
		$limit: Int
		$nextToken: String
	) {
		listRejectedByPreferencesByDate(
			preferencesId: $preferencesId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				businessProfileId
				businessUserId
				shortlistedbyId
				shortlistedByUser {
					createdAt
					username
					businessProfileId
					id
					cognitoId
					companyprofile {
						createdAt
						name
						businessUserId
						id
						preferences
						about
						updatedAt
						owner
						__typename
					}
					email
					updatedAt
					owner
					__typename
				}
				UserId
				about
				candidateprofileId
				Reasonofrejection
				recommededId
				preferencesId
				jobType
				id
				candidateprofile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about

					salarycurrent
					salaryexpectation
					certification
					noticeperiod
					currentoffer
					education
					experience
					expertise
					graduation
					image
					resume
					location
					imageicon1
					link1
					imageicon2
					link2
					imageicon3
					link3
					profileviews
					relevant
					IntroVideo
					connected
					imagekey
					typeofwork
					resumekey
					updatedAt
					owner
					__typename
				}

				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const checkRecommendeduser = /* GraphQL */ `
	query CheckRecommendeduser(
		$candidateprofileId: ID!
		$preferencesId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelrecommendedprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		checkRecommendeduser(
			candidateprofileId: $candidateprofileId
			preferencesId: $preferencesId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
			}
			__typename
		}
	}
`

export const checkRejecteduser = /* GraphQL */ `
	query CheckRejecteduser(
		$candidateprofileId: ID!
		$preferencesId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelRejectedFilterInput
		$limit: Int
		$nextToken: String
	) {
		checkRejecteduser(
			candidateprofileId: $candidateprofileId
			preferencesId: $preferencesId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
			}

			__typename
		}
	}
`

export const checkShortlisteduser = /* GraphQL */ `
	query CheckShortlisteduser(
		$candidateprofileId: ID!
		$preferencesId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelshortlistFilterInput
		$limit: Int
		$nextToken: String
	) {
		checkShortlisteduser(
			candidateprofileId: $candidateprofileId
			preferencesId: $preferencesId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
			}

			__typename
		}
	}
`
export const CheckShortlisteduser = /* GraphQL */ `
	query CheckShortlisteduser(
		$candidateprofileId: ID!
		$preferencesId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelshortlistFilterInput
		$limit: Int
		$nextToken: String
	) {
		checkShortlisteduser(
			candidateprofileId: $candidateprofileId
			preferencesId: $preferencesId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
			}

			__typename
		}
	}
`

export const getJobPost = /* GraphQL */ `
	query GetJobPost($id: ID!) {
		getJobPost(id: $id) {
			id
			CompanyPreferenceId
			CompanyPreference {
				createdAt
				id
				rolename
				companyprofile {
					createdAt
					name
					businessUserId
					LogoImageKey
					id
					preferences
					about
					updatedAt
					owner
					__typename
				}
				businessUserId
				businessProfileId
				package
				certifications
				noticeperiod
				maxExperienceRequired
				minExperienceRequired
				modeofwork
				JobDomain
				other
				previousrole
				combinedrole
				currencyCountry
				JobType
				Location
				JobPublished
				updatedAt
				owner
				__typename
			}
			companyName
			jobPostLink
			logoUrl
			htmldescription
			CompanyDescription
			AvailablityStatus
			experienceRequired
			jobType
			Location
			skills
			domain
			description
			ShortDesc
			title
			CapabilitiesRequired {
				items {
					id
					jobPostID
					GlobalCapabilityConfigID
					CapabilityConfig {
						id
						name
						tag
						domain
						createdAt
						weeklyActiveOpportunity
						capabilitiesClaimedCount
						CapabilityPoints
						capabilitiesClaimed {
							nextToken
							__typename
						}
						ActivityStatus
						updatedAt
						owner
						__typename
					}
					createdAt
					updatedAt
					owner
					__typename
				}
				nextToken
				__typename
			}
			CapabilitiesRequiredJSON
			RolesandResponsibility
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const list_JobApplications_by_ProfileId_filter_byJobPostId = /* GraphQL */ `
	query List_JobApplications_by_ProfileId_filter_byJobPostId(
		$ProfileId: ID!
		$JobPostId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelJobApplicationsFilterInput
		$limit: Int
		$nextToken: String
	) {
		list_JobApplications_by_ProfileId_filter_byJobPostId(
			ProfileId: $ProfileId
			JobPostId: $JobPostId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				JobPostId
				CompanyProfileId
				ProfileId
				CompanyPreferenceId
				ApplyingStatus
				Level
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const GetshortlistByjobPostIdfilterByUserId = /* GraphQL */ `
	query GetshortlistByjobPostIdfilterByUserId(
		$JobPostId: ID!
		$UserId: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelshortlistFilterInput
		$limit: Int
		$nextToken: String
	) {
		GetshortlistByjobPostIdfilterByUserId(
			JobPostId: $JobPostId
			UserId: $UserId
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				businessProfileId
				businessUserId
				UserId
				about
				candidateprofileId
				shortlistedbyId
				JobPostId
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listrecommendedprofieByPreferencesByAcceptance = /* GraphQL */ `
	query ListrecommendedprofieByPreferencesByAcceptance(
		$preferencesId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelrecommendedprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		listrecommendedprofieByPreferencesByAcceptance(
			preferencesId: $preferencesId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				businessProfileId
				businessUserId
				UserId
				candidateprofileId
				acceptanceStatus
				ReasonofShortlist
				Reasonofrejection
				ReasonofRecommendation
				acceptanceStatus_by_CompanyUser_ID
				acceptanceStatus_by_CompanyUser_name
				recommendedbyId
				preferencesId
				JobApplicationsId
				JobPostId
				candidateprofile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about

					salarycurrent
					salaryexpectation
					certification
					noticeperiod
					currentoffer
					education
					experience
					expertise
					graduation
					image
					resume
					location
					imageicon1
					link1
					imageicon2
					link2
					imageicon3
					link3
					profileviews
					relevant
					connected
					imagekey
					typeofwork
					resumekey
					updatedAt
					owner
					__typename
				}
				new
				id
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listrecommendedprofieByPreferencesByAcceptancewithcapabilities = /* GraphQL */ `
	query ListrecommendedprofieByPreferencesByAcceptance(
		$preferencesId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelrecommendedprofileFilterInput
		$limit: Int
		$nextToken: String
	) {
		listrecommendedprofieByPreferencesByAcceptance(
			preferencesId: $preferencesId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				businessProfileId
				businessUserId
				UserId
				candidateprofileId
				acceptanceStatus
				ReasonofShortlist
				Reasonofrejection
				ReasonofRecommendation
				acceptanceStatus_by_CompanyUser_ID
				acceptanceStatus_by_CompanyUser_name
				recommendedbyId
				preferencesId
				JobApplicationsId
				JobPostId
				JobApplication {
					id
					CandidateProfileAnalysis
					createdAt
					updatedAt
					owner
					__typename
				}
				candidateprofile {
					createdAt
					name
					UserId
					Email
					id
					phone
					about
					IntroVideo
					Capabilities {
						items {
							createdAt
							ProfileId
							id
							capabilitiesCapabilityConfigId
							ConnectedHidis {
								items {
									id
								}
							}
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}

					salarycurrent
					salaryexpectation
					certification
					noticeperiod
					currentoffer
					education
					experience
					expertise
					graduation
					image
					resume
					location
					imageicon1
					link1
					imageicon2
					link2
					imageicon3
					link3
					profileviews
					relevant
					connected
					imagekey
					typeofwork
					resumekey
					updatedAt
					owner
					__typename
				}
				new
				id
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`

export const getCandidatePreferences = /* GraphQL */ `
	query GetCandidatePreferences($id: ID!) {
		getCandidatePreferences(id: $id) {
			createdAt
			id
			rolename
			jobType
			workType
			rolelevel
			benefits
			priority
			expectation
			Salaryexpectation
			ContractCompensation
			currentSalary
			companyType
			workStyle
			minimumContractPeriod
			servingNotice
			noticePeriod
			workSchedule
			Activelylooking
			contractType
			compensationStructure
			AvailableDate
			Country
			CurrencyCountry
			userDomain
			roletype
			location1
			location2
			location3
			ActivatePreference
			profileId
			updatedAt
			owner
			__typename
		}
	}
`
export const listSkillsByUserIdByDate = /* GraphQL */ `
	query ListSkillsByUserIdByDate(
		$UserId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelSkillFilterInput
		$limit: Int
		$nextToken: String
	) {
		listSkillsByUserIdByDate(
			UserId: $UserId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				createdAt
				profileId
				UserId
				id
				Skill
				YearsOfExperience
				updatedAt
				owner
				JoinedCapabilities {
					items {
						id
						UserId
						SkillId
						CapabilityId
						Capabilities {
							createdAt
							ProfileId
							id
							capabilitiesCapabilityConfigId
							ConnectedHidis {
								items {
									CapabilityId
									HidiId
									createdAt
									id
									updatedAt
									owner
									__typename
								}
								nextToken
								__typename
							}
							updatedAt
							owner
							__typename
						}

						createdAt
						updatedAt
						owner
						__typename
					}
					nextToken
					__typename
				}
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const getJobApplications = /* GraphQL */ `
	query GetJobApplications($id: ID!) {
		getJobApplications(id: $id) {
			id
			CandidateProfileAnalysis
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`

export const getSkill = /* GraphQL */ `
	query GetSkill($id: ID!) {
		getSkill(id: $id) {
			createdAt
			profileId
			UserId
			description
			YearsOfExperience
			id
			Skill
			JoinedCapabilities {
				items {
					id
					UserId
					SkillId
					CapabilityId
					Capabilities {
						createdAt
						ProfileId
						id
						JoinedSkills {
							items {
								id
								UserId
								SkillId
								CapabilityId
								Skill {
									createdAt
									profileId
									UserId
									id
									YearsOfExperience
									Skill
									updatedAt
									owner
									__typename
								}
								GlobalCapabilityConfigId
								GlobalCapabilityConfigName
								createdAt
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						ConnectedHidis {
							items {
								CapabilityId
								HidiId
								Capability {
									CapabilityConfig {
										name
										id
									}
									__typename
								}
								Hidi {
									createdAt
									id
									profileId
									name
									UserId
									summary
									description
									title
									challenges {
										items {
											createdAt
											id
											challenge
											HidiId
											updatedAt
											owner
											__typename
										}
										nextToken
										__typename
									}

									Hack
									experience
									helped
									ProbabilityFake
									ProbabilityReal
									whereitwasdone
									AiGenerated
									problem
									hidiTopic
									updatedAt
									owner
									__typename
								}
								createdAt
								id
								updatedAt
								owner
								__typename
							}
							nextToken
							__typename
						}
						updatedAt
						owner
						__typename
					}
					owner
					__typename
				}
				nextToken
				__typename
			}
			updatedAt
			owner
			__typename
		}
	}
`

export const getPreferences = /* GraphQL */ `
	query GetPreferences($id: ID!) {
		getPreferences(id: $id) {
			createdAt
			id
			rolename
			businessUserId
			businessProfileId
			package
			certifications
			noticeperiod
			maxExperienceRequired
			minExperienceRequired
			modeofwork
			JobDomain
			other
			previousrole
			combinedrole
			currencyCountry
			JobType
			Location
			JobPublished
			updatedAt
			owner
			__typename
		}
	}
`

export const getPreferenceSkillandtraits = /* GraphQL */ `
	query GetPreferences($id: ID!) {
		getPreferences(id: $id) {
			createdAt
			id
			JobPosts {
				items {
					id
					skills
					owner
					__typename
				}
				nextToken
				__typename
			}
			JobTraits {
				items {
					id
					TraitName
					createdAt
					updatedAt
					preferencesID
					owner
					__typename
				}
				nextToken
				__typename
			}
			updatedAt
			owner
			__typename
		}
	}
`
export const getPreferenceCapability = /* GraphQL */ `
	query GetPreferences($id: ID!) {
		getPreferences(id: $id) {
			createdAt
			id
			JobDomain
			JobPosts {
				items {
					id
					CapabilitiesRequired {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID
							CapabilityConfig {
								id
								name
								__typename
							}
							owner
							__typename
						}
						nextToken
						__typename
					}

					owner
					__typename
				}
				nextToken
				__typename
			}
			updatedAt
			owner
			__typename
		}
	}
`
export const getFullPreferences = /* GraphQL */ `
	query GetPreferences($id: ID!) {
		getPreferences(id: $id) {
			createdAt
			id
			rolename
			businessUserId
			businessProfileId
			companyprofile {
				createdAt
				name
				businessUserId
				id
				preferences
				about
				updatedAt
				owner
				__typename
			}
			JobPosts {
				items {
					id
					CompanyPreferenceId

					jobPostLink
					logoUrl
					htmldescription
					CompanyDescription
					AvailablityStatus
					experienceRequired
					jobType
					Location
					skills
					domain
					description
					ShortDesc
					RolesandResponsibility
					title
					CapabilitiesRequired {
						items {
							id
							jobPostID
							GlobalCapabilityConfigID
							createdAt
							updatedAt
							owner
							__typename
						}
						nextToken
						__typename
					}

					CapabilitiesRequiredJSON
					createdAt
					updatedAt

					owner
					__typename
				}
				nextToken
				__typename
			}
			JobTraits {
				items {
					id
					TraitName
					createdAt
					updatedAt
					preferencesID
					owner
					__typename
				}
				nextToken
				__typename
			}
			package
			certifications
			noticeperiod
			maxExperienceRequired
			minExperienceRequired
			modeofwork
			JobDomain
			other
			previousrole
			combinedrole
			currencyCountry
			JobType
			Location
			JobPublished
			updatedAt
			owner
			__typename
		}
	}
`
export const getJobTypeFromJobPostId = /* GraphQL */ `
	query GetJobPost($id: ID!) {
		getJobPost(id: $id) {
			id
			CompanyPreference {
				id
				JobType
				__typename
			}
			createdAt
			updatedAt
			owner
			__typename
		}
	}
`
export const listJoinSkillAndCapabilitiesByUserId = /* GraphQL */ `
	query ListJoinSkillAndCapabilitiesByUserId(
		$UserId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelJoinSkillandCapabilityFilterInput
		$limit: Int
		$nextToken: String
	) {
		listJoinSkillAndCapabilitiesByUserId(
			UserId: $UserId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				UserId
				SkillId
				CapabilityId
				GlobalCapabilityConfigId
				GlobalCapabilityConfigName
				createdAt
				updatedAt
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listInitiativesBydeployedQrewId = /* GraphQL */ `
	query ListInitiativesBydeployedQrewId(
		$deployedQrewId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelInitiativeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listInitiativesBydeployedQrewId(
			deployedQrewId: $deployedQrewId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				createdAt
				updatedAt
				initiative
				keyResultsAcheived
				deployedQrewId
				companyProfileId
				PercentageContributionOnkeyResult
				candidateProfileId
				companyQrewId
				objectiveId
				approvalStatus
				ObjectiveKeyResult {
         			
           			 id
            		createdAt
            		updatedAt
            		keyResult
            		deployedQrewId
					progresssPercentage
            		companyProfileId
            		candidateProfileId
            		companyQrewId
					 objectiveId
					 objectiveId
            		owner
            		__typename
       
          __typename
        }
				
				objective {
					title
				}
				InitiativeCreatedBydeployedQrew {
					companyQrew {
						qrewname
					}
					deployedUserProfile {
						name
						UserId
						id
					}
				}
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listObjectiveKeyResultsbyObjectiveId = /* GraphQL */ `
  query ListObjectiveKeyResultsbyObjectiveId(
    $objectiveId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveKeyResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectiveKeyResultsbyObjectiveId(
      objectiveId: $objectiveId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
		progresssPercentage
        companyQrewId
        objectiveId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listObjectiveKeyResultsbyObjectiveIdAccordian = /* GraphQL */ `
  query ListObjectiveKeyResultsbyObjectiveId(
    $objectiveId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectiveKeyResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectiveKeyResultsbyObjectiveId(
      objectiveId: $objectiveId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
		progressStatus
		initiatives {
          items {
            id
            InitiativeCreatedBydeployedQrew {
			  deployedUserProfile {
				id
				name
				__typename
			  }
              id
              __typename
            }
            
          }
          nextToken
          __typename
        }
        companyQrewId
		progresssPercentage
        objectiveId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listInitiativesByObjectiveId = /* GraphQL */ `
  query ListInitiativesByObjectiveId(
    $objectiveId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitiativesByObjectiveId(
      objectiveId: $objectiveId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
			items {
				id
				createdAt
				updatedAt
				initiative
				keyResultsAcheived
				deployedQrewId
				companyProfileId
				PercentageContributionOnkeyResult
				candidateProfileId
				companyQrewId
				objectiveId
				approvalStatus
				ObjectiveKeyResult {
         			
           			 id
            		createdAt
            		updatedAt
            		keyResult
            		deployedQrewId
					progresssPercentage
            		companyProfileId
            		candidateProfileId
            		companyQrewId
					 objectiveId
					 objectiveId
            		owner
            		__typename
       
          __typename
        }
				
				objective {
					title
				}
				InitiativeCreatedBydeployedQrew {
					companyQrew {
						qrewname
					}
					deployedUserProfile {
						name
						UserId
						id
					}
				}
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`




export const listInistativeByObjectiveKeyResultId = /* GraphQL */ `
  query ListInistativeByObjectiveKeyResultId(
    $objectiveKeyResultId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitiativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInistativeByObjectiveKeyResultId(
      objectiveKeyResultId: $objectiveKeyResultId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
			items {
				id
				createdAt
				updatedAt
				initiative
				initiativeDocumentation
				keyResultsAcheived
				deployedQrewId
				companyProfileId
				PercentageContributionOnkeyResult
				TimeRequiredToComplete
				candidateProfileId
				companyQrewId
				objectiveId
				approvalStatus
				ObjectiveKeyResult {
         			
           			 id
            		createdAt
            		updatedAt
            		keyResult
            		deployedQrewId
					progresssPercentage
            		companyProfileId
            		candidateProfileId
            		companyQrewId
					 objectiveId
					 objectiveId
            		owner
            		__typename
       
          __typename
        }
				
				objective {
					title
				}
				InitiativeCreatedBydeployedQrew {
					companyQrew {
						qrewname
					}
					deployedUserProfile {
						name
						UserId
						id
					}
				}
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listObjectivesByCompanyQrewId = /* GraphQL */ `
	query ListObjectivesByCompanyQrewId(
		$companyQrewId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelObjectiveFilterInput
		$limit: Int
		$nextToken: String
	) {
		listObjectivesByCompanyQrewId(
			companyQrewId: $companyQrewId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				createdAt
				updatedAt
				title
				creator
				companyProfileId
				companyUserId
				deployedQrewId
				companyQrewId

				
				initiatives {
					items {
						id
						createdAt
						updatedAt
						initiative
						PercentageContributionOnkeyResult
						keyResultsAcheived
						deployedQrewId
						candidateProfileId
						companyQrewId
						objectiveId
						
						InitiativeCreatedBydeployedQrew {
							createdAt
							updatedAt
							candidateprofileId
							deployedUserProfile {
								id
								UserId
								name
							}
							qrewPosition
							preferencesId
							WorkingOnit
							id
							owner
							__typename
						}
						owner
						__typename
					}
					nextToken
					__typename
				}
		
				editHistory {
					items {
						id
						createdAt
						prevTitle
						editbyCompanyUserProfile {
							username
						}
					}
				}
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listObjectivesToUpdateByQrewId = /* GraphQL */ `
	query ListObjectivesByCompanyQrewId(
		$companyQrewId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelObjectiveFilterInput
		$limit: Int
		$nextToken: String
	) {
		listObjectivesByCompanyQrewId(
			companyQrewId: $companyQrewId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				createdAt
				updatedAt
				title
				creator
				companyProfileId
				companyUserId
				deployedQrewId
				companyQrewId
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`

export const listInitiativesByCompanyQrewId = /* GraphQL */ `
	query ListInitiativesByCompanyQrewId(
		$companyQrewId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelInitiativeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listInitiativesByCompanyQrewId(
			companyQrewId: $companyQrewId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				createdAt
				updatedAt
				initiative
				keyResultsAcheived
				PercentageContributionOnkeyResult
				deployedQrewId
				candidateProfileId
				companyQrewId
				objectiveId
				approvalStatus
				ObjectiveKeyResult {
         			 
           			 id
            		createdAt
            		updatedAt
            		keyResult
            		deployedQrewId
					progresssPercentage
            		companyProfileId
            		candidateProfileId
            		companyQrewId
					 objectiveId
					 objectiveId
            		owner
            		__typename
         
          __typename
        }
				
				objective {
					title
				}
				InitiativeCreatedBydeployedQrew {
					companyQrew {
						qrewname
					}
					deployedUserProfile {
						name
						UserId
						id
					}
				}
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listObjectivesByCompanyProfileId = /* GraphQL */ `
	query ListObjectivesByCompanyProfileId(
		$companyProfileId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelObjectiveFilterInput
		$limit: Int
		$nextToken: String
	) {
		listObjectivesByCompanyProfileId(
			companyProfileId: $companyProfileId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				createdAt
				updatedAt
				title
				creator
				companyProfileId
				companyUserId
				deployedQrewId
				companyQrewId
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`
export const listInitiativesByCompanyProfileId = /* GraphQL */ `
	query ListInitiativesByCompanyProfileId(
		$companyProfileId: ID!
		$createdAt: ModelStringKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelInitiativeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listInitiativesByCompanyProfileId(
			companyProfileId: $companyProfileId
			createdAt: $createdAt
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				createdAt
				updatedAt
				initiative
				keyResultsAcheived
				PercentageContributionOnkeyResult
				deployedQrewId
				companyProfileId
				candidateProfileId
				companyQrewId
				objectiveId
				approvalStatus
				ObjectiveKeyResult {
         			
           			 id
            		createdAt
            		updatedAt
            		keyResult
            		deployedQrewId
					progresssPercentage
            		companyProfileId
            		candidateProfileId
            		companyQrewId
					 objectiveId
					 objectiveId
            		owner
            		__typename
       
          __typename
        }
			
				objective {
					title
				}
				InitiativeCreatedBydeployedQrew {
					companyQrew {
						qrewname
					}
					deployedUserProfile {
						name
						UserId
						id
					}
				}
				owner
				__typename
			}
			nextToken
			__typename
		}
	}
`

export const list_JobApplications_by_ProfileId_filter_byJobPostIdforFeed = /* GraphQL */ `
  query List_JobApplications_by_ProfileId_filter_byJobPostId(
    $ProfileId: ID!
    $JobPostId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    list_JobApplications_by_ProfileId_filter_byJobPostId(
      ProfileId: $ProfileId
      JobPostId: $JobPostId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            companyprofile {
              name
  
              owner
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
        
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
        
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
         
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $filter: ModelPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
		items {
			createdAt
			id
			rolename
			businessUserId
			JobPosts {
				items {
					id
				}
			}
			companyQrewId
			businessProfileId
			package
			maxExperienceRequired
			minExperienceRequired
			certifications
			noticeperiod
			modeofwork
			other
			previousrole
			combinedrole
			JobPublished
			recommendedprofiles {
				items {
					createdAt
					businessProfileId
					businessUserId
					UserId
					candidateprofileId
					acceptanceStatus
					Reasonofrejection
				}
				nextToken
			}
			shortlistedProfiles {
				items {
					createdAt
					businessProfileId
					businessUserId
					UserId
					candidateprofileId
				}
				nextToken
			}
			updatedAt
			owner
		}
      nextToken
      __typename
    }
  }`

export const listsPreferencesByBusinessProfileIdIdByDate = /* GraphQL */ `
query ListsPreferencesByBusinessProfileIdIdByDate(
  $businessProfileId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelpreferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  listsPreferencesByBusinessProfileIdIdByDate(
	businessProfileId: $businessProfileId
	createdAt: $createdAt
	sortDirection: $sortDirection
	filter: $filter
	limit: $limit
	nextToken: $nextToken
  ) {
	  items {
		  createdAt
		  id
		  rolename
		  businessUserId
		  JobPosts {
			  items {
				  id
			  }
		  }
		  companyQrewId
		  businessProfileId
		  package
		  maxExperienceRequired
		  minExperienceRequired
		  certifications
		  noticeperiod
		  modeofwork
		  other
		  previousrole
		  combinedrole
		  JobPublished
		  recommendedprofiles {
			  items {
				  createdAt
				  businessProfileId
				  businessUserId
				  UserId
				  candidateprofileId
				  acceptanceStatus
				  Reasonofrejection
			  }
			  nextToken
		  }
		  shortlistedProfiles {
			  items {
				  createdAt
				  businessProfileId
				  businessUserId
				  UserId
				  candidateprofileId
			  }
			  nextToken
		  }
		  updatedAt
		  owner
	  }
	nextToken
	__typename
  }
}`

export const listCompanyprofiles = /* GraphQL */ `
  query ListCompanyprofiles(
    $filter: ModelCompanyprofileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyprofiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }`

export const getNewprofile = /* GraphQL */ `
  query GetNewprofile($id: ID!) {
    getNewprofile(id: $id) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
    
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
           
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
		  HourlyRateExpectedOnContract
          ActivatePreference
          prevContractCount
          profileId
        
         
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
	  
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }`
